<template>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <ol class="breadcrumb float-left ml-2">
                        <li class="breadcrumb-item active">
                            <router-link to="/dashboard">
                                <i class="fa fa-home"></i>
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">madah</li>
                    </ol>
                    <router-link to="/dashboard">
                        <button class="btn btn-sm btn-primary float-right">
                            <i class="fa fa-arrow-circle-left"></i> back
                        </button>
                    </router-link>    
                </div>
            </div>
        </div>
    </div>  
    <section class="content">
        <div class="container-fluid">
            <div class="col-12">
                <h3 class="text-right font-amiri-header" dir="rtl">كوتيفن مركه</h3>
            </div>
            <div class="d-flex flex-row-reverse">
                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                    <select class="form-control" v-model="agr_id">
                        <option v-for="(item, index) in classroom" :value="item.agr_id" :key="index">
                            {{ item.agr_grade }}/{{ item.agrroom_name }} [{{ item.agr_semester }}/{{ item.agr_academic_year }}]
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-12">
                <ul class="list-group mt-2">
                    <li class="list-group-item bg-secondary mt-2 font-amiri text-right" aria-disabled="true">
                     [{{selectedRoom.agr_semester}}/{{selectedRoom.agr_academic_year}}] {{selectedRoom.agr_grade}}/{{selectedRoom.agrroom_name}}  كلس 
                    </li>
                    <li class="list-group-item" v-for="(item, index) in semesterSubjects" :key="index">
                        <div style="display: flex; justify-content: space-between;">
                            <span>
                                {{ item.sujcode_agm }}   
                            </span>
                            <span class="font-amiri text-right" dir="rtl">
                                <router-link :to="{name: 'AgamaScoreDetail', params:{agr_id_code: item.agr_id_code, agrq_id_code: item.agrq_id_code, suj_agm_id_code: item.suj_agm_id_code, stf_id_code: item.stf_id_code}}">                                  
                                    <button class="btn btn-secondary btn-sm ml-3">
                                        <i class="fa fa-arrow-circle-right"></i>
                                    </button>
                                </router-link>
                                
                                {{ item.sujname_agm_ar }} 
                                <div class="text-muted text-right mt-2 mr-5">
                                   أ. {{ item.stf_name_ar }} {{ item.stf_lastname_ar }} 
                                </div>
                            </span>
                        </div>
                    </li>
                </ul>
                <br>
            </div>
        </div>
    </section>
</template>

<script>
    import http from '@/services/auth';
    export default {
        name: "AgamaScore",
        mounted() {
            this.retreiveClassroom()
        },
        data() {
            return {
                classroom: [],
                semesterSubjects: [],
                agr_id: '',
                selectedRoom:{
                    agr_room: '',
                    agrroom_name: '',
                    agr_semester: '',
                    agr_acamidemic_year: '',
                },
                isShowAll: false,
            }
        },
        watch: {
            agr_id: function() {
                this.retreiveScorePointSubject(this.agr_id)
            },
        },
        methods: {
            retreiveScorePointSubject(agr_id){
                this.$Progress.start()
                this.semesterSubjects = []
                http.get(`agama/scorePointSubject/${agr_id}`)
                    .then(response => {
                        let semesterSubjects = response.data.subjects
                        this.semesterSubjects = semesterSubjects
                        let selectedRoom = this.classroom.filter(item => item.agr_id == this.agr_id)[0]
                        this.selectedRoom = selectedRoom
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('auth/destroyLogedIn')
                        }
                        this.$Progress.fail()
                    })
            },
            retreiveClassroom(){
                http.get(`agama/classroom`)
                    .then(response => {
                        let classroom = response.data.classrooms
                        let currentPeriod = response.data.currentPeriod
                        this.classroom = classroom
                        // let selectedRoom = classroom[classroom.length - 1]
                        let selectedRoom = classroom.filter(item => item.agr_semester == currentPeriod)[0]
                        this.agr_id = selectedRoom.agr_id
                        this.selectedRoom = selectedRoom
                        
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('auth/destroyLogedIn')
                        }
                    })
            }

        },
    }
</script>

<style lang="scss" scoped>

</style>