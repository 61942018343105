<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <router-link to="/" class="brand-link">
      <img src="@/assets/img/bwss_logo.jpg" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
      <span class="brand-text font-weight-light">BWSS - STUDENT</span>
    </router-link>


    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <!-- <img src="@/assets/img/avatar5.png" class="img-circle elevation-2" alt="User Image"> -->
        </div>
        <div class="info">
          <a href="#" class="d-block" style="line-height: 10px">{{user.std_code}}</a><br>
          <a href="#" class="d-block" style="line-height: 10px">{{user.name}} {{user.lastname}}</a>
        </div>
      </div>
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item">
            <router-link :to="{name: 'Dashboard'}" class="nav-link">
                <i class="nav-icon fas fa-home"></i>
                <p>หน้าแรก</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'SamanScore'}" class="nav-link">
                <i class="nav-icon fas fa-list-ul"></i>
                <p>คะแนนเก็บ</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'SamanResult'}" class="nav-link">
                <i class="nav-icon fas fa-list-ul"></i>
                <p>ผลการเรียน</p>
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link :to="{name: 'SamanAttendance'}" class="nav-link">
                <i class="nav-icon fas fa-list-ul"></i>
                <p>การมาเรียน</p>
            </router-link>
          </li> -->
          <li class="nav-item">
            <router-link :to="{name: 'AgamaScore'}" class="nav-link">
                <i class="nav-icon fas fa-list-ul"></i>
                <p>kutipan markah</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'AgamaResult'}" class="nav-link">
                <i class="nav-icon fas fa-list-ul"></i>
                <p>markah agama</p>
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link :to="{name: 'AgamaAttendance'}" class="nav-link">
                <i class="nav-icon fas fa-list-ul"></i>
                <p>kehadiran agama</p>
            </router-link>
          </li> -->
          <li class="nav-item" @click="logout()" style="cursor: pointer;">
            <a class="nav-link bg-danger">
              <i class="nav-icon fas fa-sign-out-alt"></i>
              <p>ออกจากระบบ</p>
            </a>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
// import http from '@/services/auth';
// import User from "@/services/User"
export default {
  mounted(){
    if(!localStorage.getItem('user')){
        this.$store.dispatch('auth/destroyLogedIn')
    }
  },
  data(){
    return{
      user: JSON.parse(localStorage.getItem('user')),
    } 
  },
  methods:{
    logout(){
      this.$Progress.start()
      this.$store.dispatch('auth/signOut')
        .then(() => {
          this.$router.push({name: 'Login'})
          this.$Progress.finish()
        })
        .catch(() => {
          this.$Progress.fail()
        });
    },
  }
}
</script>

<style>

</style>