<template>
    <div class="login-box">
        <!-- /.login-logo -->
        <div class="card card-outline card-primary">
            <div class="card-header text-center">
                <a href="#" class="h5">โรงเรียนบันนังสตาวิทยานุสรณ์</a>
            </div>
            <div class="card-body">
                <p class="login-box-msg">เข้าสู่ระบบ</p>
                <form @submit.prevent="submitLogin()">
                    <div class="input-group mb-2">
                        <input type="text" v-model="username" class="form-control" :class="{'is-invalid': serverErrors.username || serverErrors.email}" placeholder="รหัสประจำตัวนักเรียน">
                        <div class="input-group-append">
                            <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div class="text-danger mb-2" v-if="serverErrors.username || serverErrors.email">
                        {{serverErrors.username[0] || serverErrors.email[0]}}
                    </div>
                    <div class="input-group mb-2">
                        <input type="password" v-model="password" :class="{'is-invalid': serverErrors.password}" class="form-control" placeholder="รหัสผ่าน">
                        <div class="input-group-append">
                            <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="text-danger mb-2" v-if="serverErrors.password">
                        {{serverErrors.password[0]}}
                    </div>
                    <div class="row">
                        <!-- <div class="col-8">
                            <div class="icheck-primary">
                            <input type="checkbox" id="remember">
                            <label for="remember">
                                Remember Me
                            </label>
                            </div>
                        </div> -->
                        <!-- /.col -->
                        <div class="col-12">
                            <button type="submit" :disabled="disabled" class="btn btn-primary btn-block">เข้าสู่ระบบ</button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>
                <!-- <div class="social-auth-links text-center mt-2 mb-3">
                    <a href="#" class="btn btn-block btn-primary">
                    <i class="fab fa-facebook mr-2"></i> Sign in using Facebook
                    </a>
                    <a href="#" class="btn btn-block btn-danger">
                    <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
                    </a>
                </div> -->
                <!-- /.social-auth-links -->
                <!-- <p class="mb-1">
                    <a href="#">I forgot my password</a>
                </p>
                <p class="mb-0">
                    <a href="#" class="text-center">Register a new membership</a>
                </p> -->
            </div>
            <!-- <p class="text-center">update v.230718-2000</p> -->
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    </div>
</template>

<script>
    import http from "@/services/auth"
    // import { useToast } from "vue-toastification";
    // import axios from 'axios';
    // import User from "@/services/User"
    export default {
        data(){
            return{
                username: '',
                password: '',
                disabled: false,
                serverErrors: '',
            }
        },
        // setup() {
        //     // Get toast interface
        //     const toast = useToast();
        //     // Make it available inside methods
        //     return { toast }
        // },
        methods:{
            login(){
                this.disabled = true
                // this.$Progress.start()
                this.$store.dispatch('auth/signIn', {
                    username: this.username,
                    password: this.password,
                })
                    .then(() => {
                        this.disabled = false
                        this.serverErrors = ''
                        //reload page and route to dashboard
                        window.location.reload()
                        // this.$router.push({name: 'Dashboard'})
                        // window.location.href = 'dashboard'
                        // this.toast.success("I'm a toast!", {
                        //     position: "top-left",
                        //     timeout: 5000,
                        //     closeOnClick: true,
                        //     pauseOnFocusLoss: true,
                        //     pauseOnHover: true,
                        //     draggable: true,
                        //     draggablePercent: 0.6,
                        //     showCloseButtonOnHover: false,
                        //     hideProgressBar: false,
                        //     closeButton: "button",
                        //     icon: true,
                        //     rtl: false
                        // });
                        this.$Progress.finish()
                    }).catch(error => {
                        this.disabled = false
                        this.serverErrors = error.response.data.errors
                        this.$Progress.fail()
                    });
            },
            submitLogin(){
                this.disabled = true
                this.$Progress.start()
                http.get(process.env.VUE_APP_RUL_COOKIE+'sanctum/csrf-cookie').then(() => {
                    // console.log(response.data)
                    this.login()
                });
            }
            // login() {
            //     User.login(this.form)
            //         .then(() => {
            //         // this.$root.$emit("login", true);
            //         localStorage.setItem("auth", "true");
            //         this.$router.push({ name: "Dashboard" });
            //         })
            //         .catch(error => {
            //         if (error.response.status === 422) {
            //             this.serverErrors = error.response.data.errors;
            //         }
            //         });
            //     }
        }   
    }
</script>

<style lang="scss" scoped>

</style>
