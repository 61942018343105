<template>
  <footer class="main-footer">
    <strong> &nbsp; website <a href="https:bwss.ac.th">www.bwss.ac.th</a></strong>
    
    <div class="float-left d-none d-sm-inline-block">
      <b> Version</b> 1.0 
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>