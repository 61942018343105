<template>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <!-- <h1 class="m-0">Dashboard</h1> -->
                </div>
                <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item active">
                                <!-- <a href="#">หน้าแรก</a> -->
                                <!-- หน้าแรก -->
                            </li>
                            <!-- <li class="breadcrumb-item active">Dashboard v1</li> -->
                        </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="row justify-content-center">
          <!-- <img src="@/assets/img/sim_logo.png" class="img mb-3" width="200">  -->
          <img src="@/assets/img/bwss_logo.jpg" class="img mb-3" width="200"> 
          <div class="col-lg-12 align-self-center">
            <div align="center" class="bs-component">
              <h3>
                {{ user.std_code }}
              </h3> 
              <p>
                ชื่อ {{ user.name }} {{ user.lastname }}
              </p> 

              <div class="row justify-content-center">
                <div class="col-sm-12 col-md-4 col-lg-4 align-self-center">
                  <ul class="list-group">
                <li class="list-group-item bg-secondary">ภาควิชาสามัญ</li>
                <li class="list-group-item">
                  <router-link to="samanScore">
                    <button type="button" class="btn btn-default btn-lg btn-block">
                      คะแนนเก็บ
                    </button>
                  </router-link> 
                </li>
                <li class="list-group-item text-left">
                  <router-link to="samanResult">
                    <button type="button" class="btn btn-default btn-lg btn-block">ผลการเรียน</button>
                  </router-link>
                </li>
                <li class="list-group-item text-left">
                  <router-link to="samanAttendance">
                    <button type="button" class="btn btn-default btn-lg btn-block">รายงานการมาเรียน</button>
                  </router-link>
                </li>
              </ul>

              <ul class="list-group mt-3">
                <li class="list-group-item bg-secondary font-amiri">فڠاجين اݢام</li>
                <li class="list-group-item font-amiri">
                  <router-link to="agamaScore">
                    <button type="button" class="btn btn-default btn-lg btn-block">
                      كوتيفن مركه
                    </button>
                  </router-link> 
                </li>
                <li class="list-group-item font-amiri">
                  <router-link to="agamaResult">
                    <button type="button" class="btn btn-default btn-lg btn-block">
                      مركه
                    </button>
                  </router-link>
                </li>
                <li class="list-group-item font-amiri">
                  <router-link to="agamaAttendance">
                    <button type="button" class="btn btn-default btn-lg btn-block">
                      لافوران كحاضيران
                    </button>
                  </router-link>
                </li>
              </ul>
                </div>
              </div>
              <br>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
</template>

<script>
    import http from '@/services/auth'
    export default {
        mounted(){
          if(!localStorage.getItem('user')){
              this.$store.dispatch('auth/destroyLogedIn')
          }else{
            this.retreiveClassroom()
          }
        },
        data(){
          return{
            user: JSON.parse(localStorage.getItem('user')),
          }
        },
        methods: {
          retreiveClassroom(){
                http.get('saman/classroom')
                    .then(() => {
                        
                    })
                    .catch(err => {
                        if(err.response.status == 401){
                            this.$store.dispatch('auth/destroyLogedIn')
                        }
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>